.mapboxgl-popup-content {
  padding: 15px;
}

.app-mapbox-popup-title {
  font-weight: 600;
  border-bottom: 1px solid #bbb;
}

.app-mapbox-popup-content {
  max-height: 150px;
  margin-top: 5px;
  text-align: justify;
  overflow-y: auto;
  scrollbar-width: thin;
  padding: 0 5px;
}

.app-mapbox-popup-user {
  text-align: right;
  font-style: italic;
  color: #888;
}
.app-mapbox-popup-user span {
  font-weight: 600;
  color: #444;
}

.app-mapbox-popup-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #eee;
}

.app-mapbox-popup-content::-webkit-scrollbar-thumb {
  background: #bbb;
}
